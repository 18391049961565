/* Fuente POPPINS */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  font-family: 'poppins';
  font-size: 1.1vw;
  margin: 0 auto;
  scroll-behavior: smooth;
}

:root {
  --color-001: #111E13;
  --color-002: #177923;
  --color-003: #CECDCE;
  --color-004: #94B41A;
  --color-005: #004D29;
  --color-006: #81C131;
  --color-007: #F8F8EF;
}

.App main {
  width: 75%;
  margin: auto;
  position: relative;
  z-index: 3;
  padding-bottom: 6%;
}

@media (max-width: 1024px) and (min-width: 501px) {
  .App main {
    width: 85%;
  }
}

@media (max-width: 500px) {
  .App main {
    width: 95%;
    margin: auto;
    position: relative;
    z-index: 3;
    padding-bottom: 6%;
  }
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

body::before {
  width: 100%;
  height: 100%;
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  background-image: url(/src/img/background_web.png);
  background-size: contain;
  z-index: 1;
  filter: opacity(25%);
}

body::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background: linear-gradient(to right, var(--color-002), white, var(--color-002));
  z-index: 1;
  filter: opacity(25%);
}