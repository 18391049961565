/* =========================

    NOSOTROS

===========================*/

.nosotros {
    position: relative;
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.3vw;
    overflow: hidden;
    color: var(--color-007);
}

.nosotros::after {
    content: '';
    width: 100%;
    height: 10%;
    top: 0;
    left: 0;
}

.nosotros .contenido {
    position: absolute;
    width: 100%;
    height: 100%;
}

.nosotros::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        45deg, 
        var(--color-002), 
        var(--color-005), 
        var(--color-002), 
        var(--color-005), 
        var(--color-002)
    );
    filter: opacity(75%);
}

.nosotros .contenido {
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nosotros .imagen {
    overflow: hidden;
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nosotros .imagen img {
    width: 100%;
    position: relative;
    top: 79%;
}

.nosotros h2 {
    letter-spacing: .05vw;
    font-size: 1.9rem;
}

.nosotros p {
    font-size: 1rem;
}

/* Movil */
@media (max-width: 500px) {
    .nosotros {
        height: 300px;
    }

    .nosotros h2 {
        font-size: 4.9rem;
    }
    
    .nosotros p {
        font-size: 2.9rem;
    }

    .nosotros .imagen {
        height: 30%;
    }

    .nosotros .imagen img {
        top: 69%;
    }
    
}

/* Tablet */
@media (max-width: 1024px) and (min-width: 501px) {
    .nosotros {
        height: 30vh;
        border-radius: 2.9vw;
    }

    .nosotros h2 {
        font-size: 2.9rem;
    }
    
    .nosotros p {
        font-size: 1.9rem;
    }
}

/* ========================

    SERVICIOS

==========================*/

.titulo-servicios {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
    margin: 4% 0;
}

.titulo-servicios h3 {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--color-001);
}

.titulo-servicios h2 {
    color: var(--color-002);
    font-size: 2rem;
} 

@media (max-width: 500px) {
    .titulo-servicios {
        gap: 2%;
    }

    .titulo-servicios h3 {
        font-size: 4.5rem;
    }
    
    .titulo-servicios h2 {
        font-size: 6rem;
    } 
}

@media (max-width: 1024px) and (min-width: 501px) {
    .titulo-servicios h3 {
        font-size: 2.9rem;
    }
    
    .titulo-servicios h2 {
        font-size: 2.9rem;
    } 
}