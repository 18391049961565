.form-contact {
    margin: 4% 0;
    box-shadow: -1vw 1vw .5vw rgba(0, 0, 0, 0.19);
}

.enviar {
    all: unset;
}

.form-contact {
    width: 100%;
    height: 65vh;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    border-radius: 1.5vw;
}

.form-contact::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--color-005), var(--color-002));
    left: 0;
    top: 0;
    z-index: 1;
    filter: opacity(75%);
}

.form-contact .form-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
}

.form-contact .form-wrap .left {
    width: 45%;
    height: 100%;
}

.form-contact .form-wrap .right {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6%;
    align-items: end;
    gap: 3%;
}

.form-contact .form-wrap .right .name,
.form-contact .form-wrap .right .email,
.form-contact .form-wrap .right .message {
    width: 100%;
    padding: 1.5% 0;
    padding-left: 2%;
    font-size: .8rem;
    letter-spacing: .05rem;
}

.form-contact .form-wrap .right .message {
    height: 35%;
}

.form-contact .form-wrap .left {
    background-color: var(--color-007);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-contact .form-wrap .left img {
    width: 75%;
}

.form-contact .form-wrap .right .enviar {
    background-color: var(--color-006);
    color: var(--color-007);
    padding: 2% 4%;
    font-size: .8rem;
    cursor: pointer;
}

@media (max-width: 500px) {
    .form-contact .form-wrap {
        display: flex;
        flex-direction: column;
    }

    .form-contact .form-wrap .left,
    .form-contact .form-wrap .right {
        width: 100%;
    }

    .form-contact .form-wrap .left {
        height: 40%;
    }
    
    .form-contact .form-wrap .right {
        height: 60%;
    }

    .form-contact .form-wrap .left img {
        width: 60%;
    }

    .form-contact .form-wrap .right .name,
    .form-contact .form-wrap .right .email,
    .form-contact .form-wrap .right .message {
        font-size: 3rem;
    }

    .form-contact .form-wrap .right .enviar {
        font-size: 3rem;
    }
}




@media (max-width: 1024px) and (min-width: 501px) {

    .form-contact {
        height: 50vh;
        border-radius: 2.9vw;
    }

    .form-contact .form-wrap {
       display: flex;
       flex-direction: column;
    }

    .form-contact .form-wrap .left,
    .form-contact .form-wrap .right {
        width: 100%;
    }

    .form-contact .form-wrap .left {
        height: 40%;
    }
    
    .form-contact .form-wrap .right {
        height: 60%;
    }

    .form-contact .form-wrap .left img {
        height: 75%;
        width: auto;
    }

    .form-contact .form-wrap .right .name,
    .form-contact .form-wrap .right .email,
    .form-contact .form-wrap .right .message {
        font-size: 1.9rem;
    }

    .form-contact .form-wrap .right .enviar {
        font-size: 1.9rem;
        cursor: unset;
    }

}