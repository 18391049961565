.redes-footer {
    width: 12.5vw;
    height: 5.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.redes-footer img,
.redes-footer .pleca {
    position: absolute;
}

.redes-footer img {
    height: 100%;
}

.redes-footer .pleca {
    opacity: 0;
}

.pleca {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.redes-footer:hover .imagen-redes-footer {
    animation-name: animacion;
    animation-duration: 100ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes animacion {
    0% {
        left: 100px;
    }

    100% {
        left: 0px;
    }
}

.redes-footer:hover .pleca {
    animation-name: animacion-pleca;
    animation-duration: 100ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes animacion-pleca {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        background-color: rgba(255, 255, 255);
        width: 81.5%;
        height: 70%;
        left: 18.5%;
        border-radius: 0 1vw 1vw 0;
    }
}

/* ======== Movil ============== */
@media (hover: none) and (pointer: coarse) {
    .redes-footer:hover .imagen-redes-footer,
    .redes-footer:hover .pleca {
        animation: none;
    }
}



@media (max-width: 1024px) and (min-width: 501px) {

    .redes-footer img {
        height: 80%;
    }

    .redes-footer:hover .imagen-redes-footer {
        animation-name: unset;
    }

    .redes-footer:hover .pleca {
        animation-name: unset;
    }

    .redes-footer .pleca {
        display: none;
    }
    
}
