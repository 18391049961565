footer {
    width: 100%;
    height: 46vh;
    background: linear-gradient(to right, var(--color-005), var(--color-001));
    display: flex;
    position: absolute;
    z-index: 100;
}

footer .col_1,
footer .col_2,
footer .col_3 {
    width: 33.33%;
    height: 100%;
}

/* Columna 1 */
footer .col_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4%;
}

footer .col_1 img {
    width: 49%;
}

footer .col_1 a {
    color: var(--color-007);
    width: 100%;
    text-align: start;
    padding-left: 24%;
    font-size: .9rem;
}

/* Columna 2 */

footer .col_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9%;
}

footer .col_2 h2 {
    color: var(--color-007);
    font-size: .9rem;
    font-weight: 400;
    letter-spacing: .04rem;
}

footer .col_3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4%;
}

/* ========= Movil ============== */
@media (max-width:500px) {
footer {
    width: 100%;
    height: 69vh;
    display: flex;
    flex-direction: column;
    z-index: 50;
}

footer .col_2 {
    height: 49%;
}

footer .col_3 {
    display: flex;
    flex-direction: row;
    gap: 9%;
    height: 39%;
}

footer .col_1,
footer .col_2,
footer .col_3 {
    width: 100%;
}

footer .col_1 a {
    color: var(--color-007);
    width: 100%;
    text-align: start;
    padding-left: 24%;
    font-size: 4rem;
}

footer .col_2 h2 {
    font-size: 3.9rem;
}

}




@media (max-width: 1024px) and (min-width: 501px) {

    footer .col_3 {
        gap: 2%;
    }

    footer .col_1 img {
        width: 80%;
    }
    
    footer .col_1 a {
        font-size: 1.9rem;
    }

    footer .col_2 h2 {
        font-size: 1.9rem;
    }

}