.whatsapp {
    width: 75%;
    height: 24vh;
    margin: auto;
    background-image: url(/src//img/banner_contacto_wa.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 0;
    transition: all 200ms;
    cursor: pointer;
}

.whatsapp .content {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    padding-right: 6%;
}

.whatsapp .content .content-wrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.content-wrap .title-1,
.content-wrap .title-2,
.content-wrap .logo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.content-wrap .title-1 {
    justify-content: start;
}

.content-wrap .title-2 {
    justify-content: end;
    text-align: end;
    filter: opacity(0);
}

.content-wrap .title-1,
.content-wrap .title-2 {
    color: var(--color-007);
    font-size: 1.3rem;
}

.content-wrap .logo img {
    width: 25%;
    position: relative;
    left: 60%;
    transition: all 200ms;
}

.whatsapp:hover {
    background-position: center left .2%;
}

.whatsapp:hover .imagen-wa {
    left: -10%;
}

.whatsapp:hover .title-1 {
    filter: opacity(0);
}

.whatsapp:hover .title-2 {
    filter: opacity(1);
}

@media (hover:none) and (pointer: coarse) {
    
    .whatsapp:hover {
        background-position: center left 19%;
    }
    
    .whatsapp:hover .imagen-wa {
        left: 60%;
    }
    
    .whatsapp:hover .title-1 {
        filter: opacity(1);
    }
    
    .whatsapp:hover .title-2 {
        filter: opacity(0);
    }

}

@media (max-width: 500px) {

    .whatsapp,
    .content-wrap .logo img {
        transition: unset;
    }

    .whatsapp {
        height: 100px;
        background-position: center left 19%;
    }

    .whatsapp .title-1 {
        font-size: 2rem;
    }

    .whatsapp .content {
        padding-right: 0;
    }
}





@media (max-width: 1024px) and (min-width: 501px) {
    .whatsapp {
        width: 100%;
        height: 19vh;
        background-position: center left 19%;
        background-size: cover;
    }

    .content-wrap .title-1 {
        font-size: 2.6rem;
        left: -2%;
    }

    .content-wrap .logo img {
        width: 29%;
        left: 69%;
    }

    /* Null Hovering */
    .whatsapp:hover {
        background-position: center left 19%;
    }
    
    .whatsapp:hover .imagen-wa {
        left: 69%;
    }
    
    .whatsapp:hover .title-1 {
        filter: opacity(1);
    }
    
    .whatsapp:hover .title-2 {
        filter: opacity(0);
    }
}

