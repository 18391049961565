.swiper {
  border-radius: 1.5vw;
  overflow: hidden;
  margin: 1% auto 4% auto;
  width: 90%;
  z-index: 3;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  width: 75%;
  padding: 1%;
  background-color: rgba(255, 255, 255, 0.4);
}

.swiper-slide img {
  width: 100%;
  border-radius: 1vw;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-pagination {
  display: none;
}

@media (max-width: 500px) {
  .swiper {
    width: 95%;
  }
}