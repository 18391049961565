/* ========== Header ============ */
.header {
    width: 100%;
    height: 14vh;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
}

.header .top-head .left,
.header .top-head .right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header .top-head .left h2 {
    cursor: pointer;
}

.header .top-head .left {
    display: flex;
    justify-content: start;
    gap: 19px;
    position: relative;
    left: 6%;
}

.header .top-head .right {
    display: flex;
    justify-content: end;
    padding-right: 9%;
    color: var(--color-007);
}

.header .top-head .right p {
    font-size: .9rem;
}

.header .top-head {
    width: 100%;
    height: 35%;
    background: linear-gradient(to bottom, var(--color-002), var(--color-006));
    display: flex;
}

/* ====== Bottom Head ========== */
.header .bottom-head {
    width: 100%;
    height: 65%;
    background-color: var(--color-007);
    display: flex;
}

.header .bottom-head .left,
.header .bottom-head .right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
}

.header .bottom-head .left a {
    margin-left: 29%;
}

.header .bottom-head .left img {
    width: 22%
}

/* ====== Nav ========= */
.header .bottom-head .right nav {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-right: 39%;
    gap: 9%;
}

.cerrar-menu,
.header .bottom-head nav .logo-movil-nav,
.header .bottom-head .right .ham {
    display: none;
}

/* ======== Movil ============== */
@media (max-width: 500px) {
    .header .top-head .right {
        display: none;
    }

    .header .top-head {
        justify-content: center;
    }

    .header .top-head .left {
        left: 0;
        justify-content: center;
    }

    .header .bottom-head .right {
        width: 100%;
        display: flex;
        justify-content: end;
    }

    .header .bottom-head .right .ham {
        display: block;
        margin-right: 19%;
        color: var(--color-005);
    }

    .header .bottom-head nav {
        all: unset;
        width: 100% !important;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background: linear-gradient(to right, var(--color-002), var(--color-006) );
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
        gap: 2% !important;
        left: -140%;
        transition: 300ms;
    }

    .nav-on {
        left: 0 !important;
    }

    .bottom-head nav .cerrar-menu {
        all: unset;
        background-color: var(--color-006);
        color: var(--color-007);
        padding: 4%;
        border-radius: 1vw;
        font-size: 4.9rem;
    }

    .bottom-head nav li {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 9% 0;
    }

    .bottom-head nav li a {
        color: var(--color-007);
        font-size: 4.9rem;
    }

    .header .bottom-head nav .logo-movil-nav {
        display: block;
        width: 75%;
    }

    .header .bottom-head .left img {
        width: 100%
    }
}

@media (max-width: 1024px) and (min-width: 501px) {
    .header {
        height: 10vh;
    }

    .header .top-head {
        height: 50%;
    }

    .header .bottom-head {
        height: 50%;
    }

    .header .bottom-head .left img {
        width: 39%
    }

    .header .top-head .right p {
        font-size: 1.9rem;
    }
}
