.redes-head {
    background-color: var(--color-001);
    width: 1.8vw;
    padding: .29vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5vw;
    cursor: pointer;
    transition: 90ms;
}

.redes-head img {
    width: 100%;
}

.redes-head:hover {
    padding: .2vw;
    background-color: var(--color-005);
}

@media (max-width: 500px) {

    .redes-head:hover {
        padding: .9vw;
        background-color: var(--color-001);
    }

    .redes-head {
        width: 6.9vw;
        padding: .9vw;
        border-radius: 2vw;
    }

}

@media (max-width: 1024px) and (min-width: 501px) {
    .redes-head {
        width: 4.9vw;
        padding: .69vw;
        border-radius: 1vw;
        margin: 0 1rem
    }

    .redes-head:hover {
        padding: .69vw;
        background-color: var(--color-001);
    }
}