button {
    width: 20vw;
    height: 14vh;
    background-image: url(/src/img/banner_contacto_wa.jpg);
    background-position: center left;
    background-size: cover;
    border: none;
    border-radius: 1vw;
    position: relative;
    z-index: 0;
    overflow: hidden;
    cursor: pointer;
}

button img,
button .black-layer {
    position: absolute;
    top: 0;
    left: 0;
    transition: ease-out 150ms;
}

button img {
    width: 20%;
    position: relative;
    left: 19%;
    z-index: 3;
}

button:hover .black-layer {
    background-color: var(--color-001);
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: opacity(75%);
}

button:hover img {
    left: 0;
    width: 28%;
    filter: drop-shadow(0 0 .3vw white);
}

@media (hover: none) and (pointer: coarse) {
    button:hover .black-layer,
    button:hover img {
        all: unset;

        width: 20%;
        position: relative;
        left: 19%;
        z-index: 3;
    }

    button {
        width: 69rem;
        height: 26rem;
    }
}



@media (max-width: 1024px) and (min-width: 501px) {

    button {
        width: 29vw;
        height: 9vh;
        background-position: center left 10%;
    }

    button img {
        width: 36%;
        left: 25%;
    }

    button:hover .black-layer {
        all: unset;
    }
    
    button:hover img {
        left: 25%;
        width: 36%;
        filter: unset;
    }

}