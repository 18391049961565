.nav-item {
    cursor: pointer;
}

.nav-item a {
    font-size: 1rem;
    color: var(--color-005);
    transition: 90ms;
}

.nav-item a:hover {
    color: var(--color-006);
}

@media (max-width: 1024px) and (min-width: 501px) {
    .nav-item a {
        font-size: 1.9rem;
    }    
}