.card {
    width: 100%;
    height: 52vh;
    overflow: hidden;
    background: linear-gradient(to bottom, #1779229d, #80c1319d);
    display: flex;
    border-radius: 1vw;
    margin: 4% 0;
}

.card .imagen,
.card .contenido {
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-007);
    padding: 0 3%;
    gap: 4%;
}

.card .imagen img {
    height: 100%;
}

.card .contenido .separador {
    width: 50%;
    height: 3px;
    background-color: var(--color-007);
}

.card .contenido h2 {
    font-size: 1.5rem;
    text-align: center;
}

.card .contenido p {
    font-size: .9rem;
    letter-spacing: .01vw;
    text-align: justify;
}

.cambio {
    flex-direction: row-reverse;
}

/* ====== Movil ======= */
@media (max-width: 500px) {
    .card {
        height: 690px;
        flex-direction: column;
        flex-direction: column-reverse;
        border-radius: 2vw;
    }
    
    .card .imagen,
    .card .contenido {
        width: 100%;
    }

    .card .contenido h2 {
        font-size: 3.9rem;
    }
    
    .card .contenido p {
        font-size: 2.9rem;
    }
}





@media (max-width: 1024px) and (min-width: 501px) {

    .card {
        height: 64vh;
        display: flex;
        flex-direction: column;
        border-radius: 2vw;
    }

    .card .imagen,
    .card .contenido {
        width: 100%;
    }

    .card .imagen img {
        height: 112%;
    }

    .card .contenido h2 {
        font-size: 2.9rem;
    }
    
    .card .contenido p {
        font-size: 1.9rem;
    }

}